import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://apishule.x-brainlabs.online/api/v1/',
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
})

axiosInstance.interceptors.request.use(
  (config: any) => {
    const user = localStorage.getItem('user')
    if (user) {
      const token = JSON.parse(localStorage.getItem('user')!).token.token

      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

//add a response interceptor
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
export default axiosInstance
