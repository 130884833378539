import { defineStore } from 'pinia'
import axiosInstance from './../configHttp/Httpcommon'

interface Auth {
  password: string
  email: string
}

export const authStore = defineStore('auth', {
  state: () => {
    return {
      password: '',
      email: '',
      userinfo: '',
    }
  },
  getters: {
    infologin: () => JSON.parse(localStorage.getItem('user')!),
  },
  actions: {
    async login(auth: Auth) {
      return await axiosInstance.post('institution/login', auth)
    },

    saveInfo(data: any) {
      localStorage.setItem('user', JSON.stringify(data))
    },

    async logout() {
      try {
        await axiosInstance.get('log/logout')
        localStorage.removeItem('user')
      } catch (error) {
        console.log(error)
      }
    },
  },
})
