<template>
  <div class="app-navbar-actions">
    <!--<GithubButton v-if="!isMobile" class="app-navbar-actions__item" />-->

    <!-- <VaButton
      v-if="!isMobile"
      preset="secondary"
      href="https://admin.vuestic.dev/"
      target="_blank"
      color="textPrimary"
      class="app-navbar-actions__item flex-shrink-0 mx-0"
    >
     {{ t('aboutVuesticAdmin') }}  
    </VaButton>
    <VaButton
      v-if="!isMobile"
      preset="secondary"
      href="https://discord.gg/u7fQdqQt8c"
      target="_blank"
      color="textPrimary"
      class="app-navbar-actions__item flex-shrink-0 mx-0"
    >
     {{ t('helpAndSupport')  }}
    </VaButton>-->
    <!--<NotificationDropdown class="app-navbar-actions__item" />-->
   <!-- <LanguageSwitcher />-->
    <span class="sm:text-xs text-md">{{ userdata.email }}</span>

    <ProfileDropdown class="app-navbar-actions__item app-navbar-actions__item--profile mr-1" />
    <VaLayout>
      <div class="flex flex-col p-4 space-y-4 bg-backgroundSecondary rounded-lg"></div>
    </VaLayout>
  </div>
</template>

<script lang="ts" setup>
import LanguageSwitcher from '../../../pages/settings/language-switcher/LanguageSwitcher.vue'
import ProfileDropdown from './dropdowns/ProfileDropdown.vue'
//import NotificationDropdown from './dropdowns/NotificationDropdown.vue'
import { computed, onMounted } from 'vue'
import UserAuthData from '../../../logic/utils'
import { VaLayout } from 'vuestic-ui/web-components'

//const user = ref('')
onMounted(() => {
  UserAuthData().user
})

const userdata = computed(() => UserAuthData().user)

defineProps({
  isMobile: { type: Boolean, default: false },
})

//import { useI18n } from 'vue-i18n'
//const { t } = useI18n()
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
</style>
